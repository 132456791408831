import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { isString, isObject } from '@vue/shared';
export { isFunction, isObject, isString } from '@vue/shared';
import { createDefu } from 'defu';
export { createDefu as createMerge, defu as merge } from 'defu';
export { default as cloneDeep } from 'lodash.clonedeep';

function cn(...inputs) {
  return twMerge(clsx(inputs));
}

function arraysEqual(a, b) {
  if (a.length !== b.length)
    return false;
  const counter = /* @__PURE__ */ new Map();
  for (const value of a) {
    counter.set(value, (counter.get(value) || 0) + 1);
  }
  for (const value of b) {
    const count = counter.get(value);
    if (count === void 0 || count === 0) {
      return false;
    }
    counter.set(value, count - 1);
  }
  return true;
}
function diff(obj1, obj2) {
  function findDifferences(o1, o2) {
    if (Array.isArray(o1) && Array.isArray(o2)) {
      if (!arraysEqual(o1, o2)) {
        return o2;
      }
      return void 0;
    }
    if (typeof o1 === "object" && typeof o2 === "object" && o1 !== null && o2 !== null) {
      const diffResult = {};
      const keys = /* @__PURE__ */ new Set([...Object.keys(o1), ...Object.keys(o2)]);
      keys.forEach((key) => {
        const valueDiff = findDifferences(o1[key], o2[key]);
        if (valueDiff !== void 0) {
          diffResult[key] = valueDiff;
        }
      });
      return Object.keys(diffResult).length > 0 ? diffResult : void 0;
    }
    return o1 === o2 ? void 0 : o2;
  }
  return findDifferences(obj1, obj2);
}

function getElementVisibleRect(element) {
  if (!element) {
    return {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0
    };
  }
  const rect = element.getBoundingClientRect();
  const viewHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight
  );
  const top = Math.max(rect.top, 0);
  const bottom = Math.min(rect.bottom, viewHeight);
  const viewWidth = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth
  );
  const left = Math.max(rect.left, 0);
  const right = Math.min(rect.right, viewWidth);
  return {
    bottom,
    height: Math.max(0, bottom - top),
    left,
    right,
    top,
    width: Math.max(0, right - left)
  };
}
function getScrollbarWidth() {
  const scrollDiv = document.createElement("div");
  scrollDiv.style.visibility = "hidden";
  scrollDiv.style.overflow = "scroll";
  scrollDiv.style.position = "absolute";
  scrollDiv.style.top = "-9999px";
  document.body.append(scrollDiv);
  const innerDiv = document.createElement("div");
  scrollDiv.append(innerDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - innerDiv.offsetWidth;
  scrollDiv.remove();
  return scrollbarWidth;
}
function needsScrollbar() {
  const doc = document.documentElement;
  const body = document.body;
  const overflowY = window.getComputedStyle(body).overflowY;
  if (overflowY === "scroll" || overflowY === "auto") {
    return doc.scrollHeight > window.innerHeight;
  }
  return doc.scrollHeight > window.innerHeight;
}
function triggerWindowResize() {
  const resizeEvent = new Event("resize");
  window.dispatchEvent(resizeEvent);
}

function isUndefined(value) {
  return value === void 0;
}
function isBoolean(value) {
  return typeof value === "boolean";
}
function isEmpty(value) {
  if (value === null || value === void 0) {
    return true;
  }
  if (Array.isArray(value) || isString(value)) {
    return value.length === 0;
  }
  if (value instanceof Map || value instanceof Set) {
    return value.size === 0;
  }
  if (isObject(value)) {
    return Object.keys(value).length === 0;
  }
  return false;
}
function isHttpUrl(url) {
  if (!url) {
    return false;
  }
  const httpRegex = /^https?:\/\/.*$/;
  return httpRegex.test(url);
}
function isWindow(value) {
  return typeof window !== "undefined" && value !== null && value === value.window;
}
function isMacOs() {
  const macRegex = /macintosh|mac os x/i;
  return macRegex.test(navigator.userAgent);
}
function isWindowsOs() {
  const windowsRegex = /windows|win32/i;
  return windowsRegex.test(navigator.userAgent);
}
function isNumber(value) {
  return typeof value === "number" && Number.isFinite(value);
}
function getFirstNonNullOrUndefined(...values) {
  for (const value of values) {
    if (value !== void 0 && value !== null) {
      return value;
    }
  }
  return void 0;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function toLowerCaseFirstLetter(str) {
  if (!str)
    return str;
  return str.charAt(0).toLowerCase() + str.slice(1);
}
function toCamelCase(key, parentKey) {
  if (!parentKey) {
    return key;
  }
  return parentKey + key.charAt(0).toUpperCase() + key.slice(1);
}
function kebabToCamelCase(str) {
  return str.split("-").filter(Boolean).map(
    (word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
  ).join("");
}

const mergeWithArrayOverride = createDefu((originObj, key, updates) => {
  if (Array.isArray(originObj[key]) && Array.isArray(updates)) {
    originObj[key] = updates;
    return true;
  }
});

let nProgressInstance = null;
async function loadNprogress() {
  if (nProgressInstance) {
    return nProgressInstance;
  }
  nProgressInstance = await import('nprogress');
  nProgressInstance.configure({
    showSpinner: true,
    speed: 300
  });
  return nProgressInstance;
}
async function startProgress() {
  const nprogress = await loadNprogress();
  nprogress?.start();
}
async function stopProgress() {
  const nprogress = await loadNprogress();
  nprogress?.done();
}

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
class StateHandler {
  constructor() {
    __publicField(this, "condition", false);
    __publicField(this, "rejectCondition", null);
    __publicField(this, "resolveCondition", null);
  }
  // 清理 resolve/reject 函数
  clearPromises() {
    this.resolveCondition = null;
    this.rejectCondition = null;
  }
  isConditionTrue() {
    return this.condition;
  }
  reset() {
    this.condition = false;
    this.clearPromises();
  }
  // 触发状态为 false 时，reject
  setConditionFalse() {
    this.condition = false;
    if (this.rejectCondition) {
      this.rejectCondition();
      this.clearPromises();
    }
  }
  // 触发状态为 true 时，resolve
  setConditionTrue() {
    this.condition = true;
    if (this.resolveCondition) {
      this.resolveCondition();
      this.clearPromises();
    }
  }
  // 返回一个 Promise，等待 condition 变为 true
  waitForCondition() {
    return new Promise((resolve, reject) => {
      if (this.condition) {
        resolve();
      } else {
        this.resolveCondition = resolve;
        this.rejectCondition = reject;
      }
    });
  }
}

async function to(promise, errorExt) {
  try {
    const data = await promise;
    const result = [null, data];
    return result;
  } catch (error) {
    if (errorExt) {
      const parsedError = Object.assign({}, error, errorExt);
      return [parsedError, void 0];
    }
    return [error, void 0];
  }
}

function traverseTreeValues(tree, getValue, options) {
  const result = [];
  const { childProps } = options || {
    childProps: "children"
  };
  const dfs = (treeNode) => {
    const value = getValue(treeNode);
    result.push(value);
    const children = treeNode?.[childProps];
    if (!children) {
      return;
    }
    if (children.length > 0) {
      for (const child of children) {
        dfs(child);
      }
    }
  };
  for (const treeNode of tree) {
    dfs(treeNode);
  }
  return result.filter(Boolean);
}
function filterTree(tree, filter, options) {
  const { childProps } = options || {
    childProps: "children"
  };
  const _filterTree = (nodes) => {
    return nodes.filter((node) => {
      if (filter(node)) {
        if (node[childProps]) {
          node[childProps] = _filterTree(node[childProps]);
        }
        return true;
      }
      return false;
    });
  };
  return _filterTree(tree);
}
function mapTree(tree, mapper, options) {
  const { childProps } = options || {
    childProps: "children"
  };
  return tree.map((node) => {
    const mapperNode = mapper(node);
    if (mapperNode[childProps]) {
      mapperNode[childProps] = mapTree(mapperNode[childProps], mapper, options);
    }
    return mapperNode;
  });
}

function uniqueByField(arr, key) {
  const seen = /* @__PURE__ */ new Map();
  return arr.filter((item) => {
    const value = item[key];
    return seen.has(value) ? false : (seen.set(value, item), true);
  });
}

function updateCSSVariables(variables, id = "__vben-styles__") {
  const styleElement = document.querySelector(`#${id}`) || document.createElement("style");
  styleElement.id = id;
  let cssText = ":root {";
  for (const key in variables) {
    if (Object.prototype.hasOwnProperty.call(variables, key)) {
      cssText += `${key}: ${variables[key]};`;
    }
  }
  cssText += "}";
  styleElement.textContent = cssText;
  if (!document.querySelector(`#${id}`)) {
    setTimeout(() => {
      document.head.append(styleElement);
    });
  }
}

function bindMethods(instance) {
  const prototype = Object.getPrototypeOf(instance);
  const propertyNames = Object.getOwnPropertyNames(prototype);
  propertyNames.forEach((propertyName) => {
    const descriptor = Object.getOwnPropertyDescriptor(prototype, propertyName);
    const propertyValue = instance[propertyName];
    if (typeof propertyValue === "function" && propertyName !== "constructor" && descriptor && !descriptor.get && !descriptor.set) {
      instance[propertyName] = propertyValue.bind(instance);
    }
  });
}
function getNestedValue(obj, path) {
  if (typeof path !== "string" || path.length === 0) {
    throw new Error("Path must be a non-empty string");
  }
  const keys = path.split(".");
  let current = obj;
  for (const key of keys) {
    if (current === null || current === void 0) {
      return void 0;
    }
    current = current[key];
  }
  return current;
}

function openWindow(url, options = {}) {
  const { noopener = true, noreferrer = true, target = "_blank" } = options;
  const features = [noopener && "noopener=yes", noreferrer && "noreferrer=yes"].filter(Boolean).join(",");
  window.open(url, target, features);
}
function openRouteInNewWindow(path) {
  const { hash, origin } = location;
  const fullPath = path.startsWith("/") ? path : `/${path}`;
  const url = `${origin}${hash ? "/#" : ""}${fullPath}`;
  openWindow(url, { target: "_blank" });
}

export { StateHandler, arraysEqual, bindMethods, capitalizeFirstLetter, cn, diff, filterTree, getElementVisibleRect, getFirstNonNullOrUndefined, getNestedValue, getScrollbarWidth, isBoolean, isEmpty, isHttpUrl, isMacOs, isNumber, isUndefined, isWindow, isWindowsOs, kebabToCamelCase, mapTree, mergeWithArrayOverride, needsScrollbar, openRouteInNewWindow, openWindow, startProgress, stopProgress, to, toCamelCase, toLowerCaseFirstLetter, traverseTreeValues, triggerWindowResize, uniqueByField, updateCSSVariables };
